.faq {
  width: 100%;
  padding: 4rem 0 7rem 0;

  h2 {
    padding-bottom: 0px;
  }
  &--collapse {
    &--btn {
      cursor: pointer;
      color: #000000;
      background-color: #eeeeee;
      padding: 18px;
      width: 100%;
      border: none;
      text-align: left;
      outline: none;
      span {
        display: flex;
        justify-content: space-between;
        transition: transform 0.3s ease-in-out;
        svg {
          align-self: flex-end;
          transform: rotate(180deg);
        }
      }
    }

    &--icon {
      &-enter {
        // opacity: 0;
        svg {
          transform: rotate(0deg);
        }
        transform: rotate(0deg);
      }
      &-enter-active {
        // opacity: 1;
        transform: rotate(180deg);
        svg {
          transform: rotate(180deg);
        }
        transition: transform 300ms;
      }
      &-exit {
        // opacity: 1;
        transform: rotate(180deg);
      }
      &-exit-active {
        opacity: 0;
        transform: rotate(0deg);
        transition: transform 300ms;
        // transform: scale(0.7);
        // transition: opacity 300ms, transform 250ms;
      }
    }

    .active,
    &--btn:hover {
      background-color: #a5999915;
    }
    .active {
      transition: all 0.3s ease-in-out;
      span {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .faq--content {
      padding: 10px 18px;
      margin-bottom: 1rem;
      max-height: max-content;
      p {
        color: #6e6e6e;
      }

      &-enter {
        opacity: 0;
        transform: scale(0.9);
      }
      &-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
      }
      &-exit {
        opacity: 1;
      }
      &-exit-active {
        opacity: 0;
        transform: scale(0.7);
        transition: opacity 300ms, transform 250ms;
      }
    }

    .expand {
      padding: 10px 18px;
      margin-bottom: 1rem;
      max-height: max-content;
    }
  }
}
