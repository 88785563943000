#treeWrapper {
  /* width: 100vw; */
  height: 100vh;
}

/* custom-tree.css */
.node__root > circle {
  fill: #4169e1;
  stroke: #4169e1;
}

.node__branch > circle {
  fill: #4169e1;
  stroke: #4169e1;
}

.node__leaf > circle {
  fill: #41e141;
  stroke: #41e141;
  r: 40;
}

.custom-link {
  stroke-width: 5;
  stroke: #a5bdff !important;
}
.rd3t-label__title {
  fill: #f0f3fa !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 99999;
}
.modal > .contents {
  background-color: white;
  border-radius: 3px;
  padding: 1rem;
  /* max-width: 500px; */
  width: 80%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  overflow-y: scroll;
}

button {
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px solid #00000000;
  border-radius: 3px;
  font-size: 1rem;
  color: white;
  background-color: #e60076;
  cursor: pointer;
}

.contents > h4 {
  margin: 0;
}

.contents > .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rd3t-label__icon {
  color: #e60076;
}

.rd3t-link {
  text-decoration: none;
  /* color: #4169e1; */
  color: #f0f3fa;
  background-color: #4169e1;
  padding: 0.7rem;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: white;
    color: #4169e1;
    font-size: 1.01rem;
  }
}
